<template>
  <div class="content">
    <div class="imgbox">
      <!-- <img :src="Picurl+img.img" alt="" /> -->
      <!-- <img src="../assets/about/banner01.png" alt="" /> -->

      <div>{{ img.cont }}</div>
      <!-- <div v-show="lang == '1'">{{ img.contEn }}</div> -->
    </div>
    <!-- 关于我们 -->
    <div class="introduction">
      <div class="middle">
        <img src="../assets/about/cg1.jpg" alt="关于我们背景图" />
        <div class="infor_text">
          <div class="title">
            <h2 >{{ AboutUsInfor[0].acTit }}</h2>
            <!-- <span v-show="lang == '1'">About us</span> -->
          </div>
          <div class="infor">
            <p v-html="AboutUsInfor[0].acContent.split('<br/>')[0]"></p>
          </div>
        </div>
      </div>
      <div class="bottom">
        <p
          style="line-height: 30px"
          v-html="AboutUsInfor[0].acContent.split('<br/>')[1]"
        ></p>
      </div>
    </div>
    <!-- 企业文化 -->
    <div id="enterprise" style="height: 60px"></div>
    <div class="enterprise">
      <div class="left" style="">
        <div class="title">
          <h2>{{ AboutUsInfor[1].acTit }}</h2>
          <!-- <span v-show="lang == '1'">Corporate Culture</span> -->
        </div>
        <div class="left_top">
          <p v-html="AboutUsInfor[1].acContent"></p>
        </div>
        <div class="left_content">
          <div class="item">
            <h3>{{ AboutUsInfor[1].articlesVoSonList[0].acTit }}</h3>
            <p v-html="AboutUsInfor[1].articlesVoSonList[0].acContent"></p>
          </div>
          <div class="item">
            <h3>{{ AboutUsInfor[1].articlesVoSonList[1].acTit }}</h3>
            <p v-html="AboutUsInfor[1].articlesVoSonList[1].acContent"></p>
          </div>
        </div>
        <div class="left_bottom">
          <h3>{{ AboutUsInfor[1].articlesVoSonList[2].acTit }}</h3>
          <p v-html="AboutUsInfor[1].articlesVoSonList[2].acContent"></p>
        </div>
      </div>
      <div class="right" style="margin-left: 40px">
        <img :src="AboutUsInfor[1].acImg" alt="企业文化背景图" />
      </div>
    </div>
    <!-- 核心优势 -->
    <div id="advantages" style="height: 20px"></div>
    <div class="advantages">
      <div class="title">
        <span></span>
        <h2>{{ AboutUsInfor[2].acTit }}</h2>
        <!-- <span v-show="lang == '1'">Core advantages</span> -->
      </div>
      <div class="text">
        <p v-html="AboutUsInfor[2].acContent"></p>
      </div>
      <div class="list">
        <div class="item">
          <span>{{ AboutUsInfor[2].articlesVoSonList[0].acTit }}</span>
          <p>
            结合自主研发的智能硬件、软件系统，为企业提供从底层设备互联的数据采集，到上层数据应用，以及打造企业级数据，管理中心的一站式智慧物联网解决方案。
          </p>
          <svg
            t="1653381105810"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="14533"
            width="64"
            height="64"
          >
            <path
              d="M551.5 400.7v230.1c0 52.3-2.9 90.3-8.5 114.1-5.7 23.8-17.4 46.1-35.4 66.9-17.9 20.7-39.5 35.6-64.8 44.7-25.4 9.1-53.7 13.6-84.9 13.6-41.2 0-75.5-4.8-102.8-14.3s-49.1-24.3-65.3-44.5c-16.2-20.2-27.7-41.4-34.6-63.7-6.8-22.3-10.2-57.8-10.2-106.3V400.7c0-63.3 5.5-110.8 16.4-142.3 11-31.5 32.7-56.8 65.3-75.8 32.5-19 72-28.5 118.4-28.5 37.8 0 71.7 6.6 101.5 19.8s52.2 29.3 66.9 48.3 24.8 40.5 30.1 64.3c5.2 23.9 7.9 61.9 7.9 114.2z m-172.3-58c0-36.6-1.8-59.6-5.3-68.8-3.5-9.3-11.9-13.8-25-13.8-12.8 0-21.3 4.9-25.4 14.7-4.1 9.8-6.1 32.4-6.1 67.9v336.5c0 40.1 1.9 64.2 5.7 72.4s12.1 12.4 25 12.4 21.2-4.8 25.1-14.5c4-9.7 6-31.4 6-65.1V342.7zM879 167.2v689.7H706.7V487.1c0-53.4-1.3-85.5-3.8-96.3-2.5-10.8-9.6-18.9-21.1-24.5-11.5-5.6-37.2-8.3-77-8.3h-17v-80.4c83.3-17.9 146.5-54.7 189.8-110.4H879z"
              p-id="14534"
              fill="rgba(255,255,255,0.2)"
            ></path>
          </svg>
        </div>
        <div class="item">
          <span>{{ AboutUsInfor[2].articlesVoSonList[1].acTit }}</span>
          <p v-html="AboutUsInfor[2].articlesVoSonList[1].acContent"></p>
          <svg
            t="1653381769576"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2626"
            width="64"
            height="64"
          >
            <path
              d="M490.9 400.7v230c0 52.2-2.8 90.3-8.5 114.2S464.9 791 447 811.8c-17.9 20.7-39.6 35.6-64.9 44.7-25.3 9.1-53.7 13.6-85 13.6-41.3 0-75.6-4.8-102.9-14.3s-49.1-24.3-65.3-44.5-27.8-41.4-34.6-63.7c-6.8-22.3-10.2-57.7-10.2-106.3V400.7c0-63.3 5.5-110.7 16.4-142.3s32.7-56.8 65.3-75.8 72.1-28.5 118.5-28.5c37.9 0 71.7 6.6 101.6 19.8 29.9 13.2 52.2 29.3 67 48.3s24.8 40.5 30.1 64.3 7.9 61.9 7.9 114.2z m-172.5-58c0-36.6-1.8-59.6-5.3-68.8-3.6-9.2-11.9-13.8-25-13.8-12.8 0-21.3 4.9-25.4 14.7-4.1 9.8-6.2 32.4-6.2 67.9v336.5c0 40 1.9 64.2 5.8 72.4 3.8 8.2 12.2 12.4 25 12.4s21.2-4.8 25.2-14.5c4-9.6 6-31.4 6-65.2l-0.1-341.6zM924.6 739.3v117.6H545.1l0.1-98.4c112.4-183.5 179.2-297 200.4-340.6s31.8-77.6 31.8-102c0-18.7-3.2-32.7-9.6-42-6.4-9.2-16.2-13.8-29.3-13.8s-22.9 5.1-29.3 15.3-9.6 30.5-9.6 60.9v65.6H545.1v-25.1c0-38.6 2-69.1 6-91.4s13.8-44.2 29.5-65.8 36-37.9 61.1-49c25-11.1 55.1-16.6 90.1-16.6 68.6 0 120.5 17 155.6 50.9 35.2 33.9 52.7 76.9 52.7 128.8 0 39.5-9.9 81.2-29.7 125.2s-78.1 137.4-174.8 280.3h189v0.1z"
              p-id="2627"
              fill="rgba(255,255,255,0.2)"
            ></path>
          </svg>
        </div>
        <div class="item">
          <span>{{ AboutUsInfor[2].articlesVoSonList[2].acTit }}</span>
          <p v-html="AboutUsInfor[2].articlesVoSonList[2].acContent"></p>
          <svg
            t="1653381802410"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="3927"
            width="64"
            height="64"
          >
            <path
              d="M483.7 400.8v229.7c0 52.2-2.8 90.2-8.5 114s-17.5 46.1-35.3 66.8C422 832 400.4 846.9 375.1 856c-25.3 9.1-53.5 13.6-84.7 13.6-41.2 0-75.3-4.8-102.6-14.3-27.2-9.5-49-24.3-65.1-44.5-16.2-20.1-27.7-41.3-34.5-63.6C81.4 725 78 689.7 78 641.2V400.8c0-63.2 5.5-110.6 16.4-142.1S127 202 159.5 183s71.9-28.5 118.1-28.5c37.7 0 71.5 6.6 101.3 19.8 29.8 13.2 52.1 29.3 66.8 48.3 14.8 19 24.8 40.4 30 64.2 5.4 23.8 8 61.8 8 114z m-172-57.9c0-36.6-1.8-59.5-5.3-68.7-3.6-9.2-11.9-13.8-24.9-13.8-12.8 0-21.2 4.9-25.3 14.7S250 307.5 250 343v336c0 40 1.9 64.1 5.7 72.3s12.1 12.3 24.9 12.3c12.8 0 21.1-4.8 25.1-14.5 4-9.6 6-31.3 6-65.1V342.9zM868.5 466.1c26.1 8.8 45.5 23.2 58.3 43.2S946 576.2 946 650c0 54.8-6.2 97.3-18.7 127.5s-34.1 53.2-64.7 69c-30.7 15.7-70 23.6-117.9 23.6-54.5 0-97.3-9.1-128.3-27.4-31.1-18.3-51.5-40.7-61.3-67.2-9.8-26.5-14.7-72.5-14.7-138.1v-54.5h172v111.9c0 29.8 1.8 48.7 5.3 56.8s11.4 12.1 23.6 12.1c13.3 0 22.1-5.1 26.4-15.3 4.3-10.2 6.4-36.9 6.4-80v-47.6c0-26.4-3-45.7-8.9-57.9-6-12.2-14.8-20.2-26.4-24s-34.2-6-67.7-6.6v-100c40.9 0 66.1-1.6 75.8-4.7 9.6-3.1 16.6-9.9 20.9-20.4s6.4-26.9 6.4-49.3v-38.3c0-24.1-2.5-40-7.4-47.6-5-7.7-12.7-11.5-23.2-11.5-11.9 0-20.1 4-24.5 12.1s-6.6 25.3-6.6 51.7v56.6h-172v-58.7c0-65.8 15-110.3 45.1-133.4s77.9-34.7 143.5-34.7c82 0 137.6 16 166.9 48 29.2 32 43.8 76.5 43.8 133.5 0 38.5-5.3 66.4-15.8 83.5-10.6 17.2-29.1 32.8-55.5 47z"
              p-id="3928"
              fill="rgba(255,255,255,0.2)"
            ></path>
          </svg>
        </div>
        <div class="item">
          <span>{{ AboutUsInfor[2].articlesVoSonList[3].acTit }}</span>
          <p v-html="AboutUsInfor[2].articlesVoSonList[3].acContent"></p>
          <svg
            t="1653381818455"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="5022"
            width="64"
            height="64"
          >
            <path
              d="M484.1 400.7v230c0 52.2-2.8 90.3-8.5 114.2s-17.5 46.1-35.4 66.9c-17.9 20.7-39.5 35.6-64.8 44.7-25.3 9.1-53.5 13.6-84.8 13.6-41.2 0-75.4-4.8-102.7-14.3-27.3-9.5-49-24.3-65.2-44.5s-27.7-41.4-34.5-63.7S78 689.9 78 641.3V400.7c0-63.3 5.5-110.7 16.4-142.3 10.9-31.5 32.7-56.8 65.2-75.8S231.5 154 277.8 154c37.8 0 71.6 6.6 101.4 19.8s52.1 29.3 66.9 48.3c14.8 19 24.8 40.5 30 64.3s8 62 8 114.3z m-172.2-58c0-36.6-1.8-59.6-5.3-68.8-3.6-9.2-11.9-13.8-24.9-13.8-12.8 0-21.2 4.9-25.4 14.7-4.1 9.8-6.2 32.4-6.2 67.9v336.5c0 40 1.9 64.2 5.8 72.4 3.8 8.2 12.1 12.4 24.9 12.4s21.2-4.8 25.1-14.5c4-9.7 6-31.4 6-65.2v-341.6zM897 167.2v450.6h49v117.6h-49v121.4h-172.2V735.4H520.3V617.9L669 167.2h228z m-172.2 450.7v-293.5l-76.1 293.5h76.1z"
              p-id="5023"
              fill="rgba(255,255,255,0.2)"
            ></path>
          </svg>
        </div>

        <div class="item">
          <span>{{ AboutUsInfor[2].articlesVoSonList[4].acTit }}</span>
          <p v-html="AboutUsInfor[2].articlesVoSonList[4].acContent"></p>
          <svg
            t="1653381936843"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="6015"
            width="64"
            height="64"
          >
            <path
              d="M479.4 400.7v230c0 52.2-2.8 90.3-8.5 114.2-5.7 23.9-17.5 46.1-35.4 66.9-17.9 20.7-39.5 35.6-64.8 44.7s-53.6 13.6-84.9 13.6c-41.2 0-75.5-4.8-102.8-14.3s-49-24.3-65.2-44.5c-16.2-20.2-27.7-41.4-34.5-63.7-6.8-22.3-10.2-57.7-10.2-106.3V400.7c0-63.3 5.5-110.7 16.4-142.3 10.9-31.5 32.7-56.8 65.2-75.8 32.6-19 72-28.5 118.3-28.5 37.8 0 71.6 6.6 101.5 19.8s52.2 29.3 66.9 48.3c14.8 19 24.8 40.5 30.1 64.3 5.2 23.9 7.9 61.9 7.9 114.2z m-172.3-58c0-36.6-1.8-59.6-5.3-68.8-3.6-9.2-11.9-13.8-24.9-13.8-12.8 0-21.3 4.9-25.4 14.7-4.1 9.8-6.2 32.4-6.2 67.9v336.5c0 40 1.9 64.2 5.8 72.4 3.8 8.2 12.2 12.4 24.9 12.4s21.2-4.8 25.2-14.5c4-9.6 6-31.4 6-65.2l-0.1-341.6zM917.3 167.2v110.3H703.7v117.1c26.7-31.5 61.1-47.3 103.2-47.3 47.5 0 83.4 13.3 107.7 39.8 24.3 26.6 36.5 77.6 36.5 153.1v98.4c0 48.8-2.3 84.7-7 107.5-4.7 22.9-14.7 44-30.1 63.5s-36.7 34.4-64.2 44.7c-27.4 10.4-60.9 15.5-100.4 15.5-44.3 0-83.4-7.7-117.3-23.2-33.8-15.5-57.9-38.5-72.1-69.2-14.2-30.7-21.3-78.1-21.3-142.3v-37.5H711v43c0 44.6 1.6 76.2 4.7 95 3.1 18.7 13.9 28.1 32.4 28.1 8.5 0 15.4-2.7 20.7-8.1 5.3-5.4 8.1-11.2 8.5-17.5 0.4-6.2 0.9-33.4 1.5-81.4V520.3c0-25.8-2.7-43.4-8.1-52.8s-14.4-14.1-26.9-14.1c-8 0-14.6 2.4-20 7.2-5.4 4.8-8.9 10-10.4 15.5-1.6 5.5-2.3 18.4-2.3 38.5H540.5l7.2-347.6h369.6v0.2z"
              p-id="6016"
              fill="rgba(255,255,255,0.2)"
            ></path>
          </svg>
        </div>
      </div>
    </div>
    <!-- 发展历程 -->
    <div id="history"></div>
    <div class="history">
      <div class="title">
        <span></span>
        <h2>{{ AboutUsInfor[3].acTit }}</h2>
        <!-- <span v-show="lang == '1'">Development history</span> -->
      </div>
      <div class="list">
        <div class="time_line">
          <!-- <div class="line"></div>
          <div class="start">
            <span></span>
          </div>
          <p
            v-for="(item, index) in AboutUsInfor[3].articlesVoSonList"
            :key="index"
          >
            <span>{{ item.acSubTit }}{{ item.acTit }}</span
            ><span v-html="item.acContent"></span>
          </p>
          <div class="end">
            <span></span>
          </div> -->
          <div style="margin-bottom: 250px"></div>
          <div class="ball"></div>
          <div class="ball ball1"></div>
          <img class="img1" src="../assets/about/sjx.png" alt="箭头图片" />
          <div class="content">
            <p class="p" style="margin-top: 35px">起航</p>
            <p class="p p1">2011-2014</p>
            <p>
              旭瑞智能科技成立<br />
              以系统集成业务为主
            </p>
          </div>
          <div class="ball ball2"></div>
          <img class="img1 img2" src="../assets/about/sjx1.png" alt="箭头图片" />
          <div class="content content1">
            <p class="p" style="margin-top: 20px">成长</p>
            <p class="p p1">2015-2016</p>
            <p>
              在齐鲁股权交易中心成长版挂牌
              <br />股权代码：301436<br />
              公司自主研发的智慧水利平台上线
            </p>
          </div>
          <div class="ball ball3"></div>
          <img class="img1 img3" src="../assets/about/sjx.png" alt="箭头图片" />
          <div class="content content2">
            <p class="p" style="margin-top: 20px">发展</p>
            <p class="p p1">2017-2018</p>
            <p>
              年营业收入突破1000万<br />获得多项专利及软著的知识产权认定<br />
              公司携智能灌溉系统赴澳洲参加农业展签约多份合作意向书
            </p>
          </div>
          <div class="ball ball4"></div>
          <img class="img1 img4" src="../assets/about/sjx1.png" alt="箭头图片" />
          <div class="content content3">
            <p class="p" style="margin-top: 20px">跨越</p>
            <p class="p p1">2019-2020</p>
            <p>
              在武汉光谷投资成立旭瑞创想<br />
              具备了软硬件产品研发与生产能力<br />
              智慧水利、智慧城管、智慧农业等系列产品面向全国广泛应用
            </p>
          </div>
          <div class="ball ball5"></div>
          <img class="img1 img5" src="../assets/about/sjx.png" alt="箭头图片" />
          <div class="content content4">
            <p class="p" style="margin-top: 30px">展望</p>
            <p class="p p1">2021-至今</p>
            <p>打造智慧物联网领军企业</p>
          </div>
          <img class="img6" src="../assets/about/sjx2.png" alt="箭头图片" />
        </div>
      </div>
    </div>
    <!-- 企业荣誉 -->
    <div id="honor" style="height: 70px"></div>
    <div class="honor" :style="{ backgroundImage: bgUrl }">
      <div class="content">
        <div class="title">
          <span></span>
          <h2>{{ AboutUsInfor[4].acTit }}</h2>
          <!-- <span v-show="lang == '1'">Enterprise honor</span> -->
        </div>
        <div class="lists">
          <div class="list">
            <div class="item">
              <svg
                t="1653440656484"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3772"
                width="32"
                height="32"
              >
                <path
                  d="M498.266719 794.482653 193.422779 479.648175l78.138638-63.594387 176.275749 140.374074c72.242348-87.230714 232.603639-260.313747 453.846532-397.963781l18.619056 43.606147C717.22457 391.518998 550.963918 658.184407 498.266719 794.482653L498.266719 794.482653 498.266719 794.482653 498.266719 794.482653zM883.151623 450.716238c3.28072 19.945261 5.009084 40.413432 5.009084 61.283762 0 207.754694-168.405501 376.161219-376.161219 376.161219-207.737298 0-376.161219-168.405501-376.161219-376.161219 0-207.746508 168.423921-376.152009 376.161219-376.152009 50.15019 0 98.014314 9.841136 141.759631 27.656897L653.759119 88.26323C608.234273 73.011874 560.683281 65.290005 511.999488 65.290005c-60.270688 0-118.779244 11.822257-173.901679 35.134195-53.203736 22.509666-100.981902 54.704926-141.987828 95.701642-40.971134 41.006949-73.183789 88.765673-95.693456 141.978618C77.103564 393.211547 65.290517 451.729312 65.290517 512c0 60.278875 11.813047 118.78743 35.126009 173.894516 22.509666 53.211922 54.722322 100.971669 95.693456 141.977595 41.005926 41.006949 88.784092 73.201186 141.987828 95.702666 55.122435 23.311939 113.63099 35.134195 173.901679 35.134195s118.779244-11.822257 173.901679-35.134195c53.203736-22.50148 100.963483-54.695716 141.970432-95.702666 41.005926-41.005926 73.200162-88.765673 95.693456-141.969409 23.330358-55.114249 35.143405-113.63099 35.143405-173.901679 0-20.652366-1.39579-41.10314-4.1536-61.283762L883.151623 450.717262 883.151623 450.716238 883.151623 450.716238zM883.151623 450.716238"
                  p-id="3773"
                  fill="#ffffff"
                ></path>
              </svg>
              <div class="text">
                <!-- <p>{{ AboutUsInfor[4].articlesVoSonList[0].acTit }}</p>
                <p v-html="AboutUsInfor[4].articlesVoSonList[0].acContent"></p> -->
                <p>2016年</p>
                <p>在齐鲁股权成长版挂牌上市（股权：301436）</p>
              </div>
            </div>
            <div class="item">
              <svg
                t="1653440656484"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3772"
                width="32"
                height="32"
              >
                <path
                  d="M498.266719 794.482653 193.422779 479.648175l78.138638-63.594387 176.275749 140.374074c72.242348-87.230714 232.603639-260.313747 453.846532-397.963781l18.619056 43.606147C717.22457 391.518998 550.963918 658.184407 498.266719 794.482653L498.266719 794.482653 498.266719 794.482653 498.266719 794.482653zM883.151623 450.716238c3.28072 19.945261 5.009084 40.413432 5.009084 61.283762 0 207.754694-168.405501 376.161219-376.161219 376.161219-207.737298 0-376.161219-168.405501-376.161219-376.161219 0-207.746508 168.423921-376.152009 376.161219-376.152009 50.15019 0 98.014314 9.841136 141.759631 27.656897L653.759119 88.26323C608.234273 73.011874 560.683281 65.290005 511.999488 65.290005c-60.270688 0-118.779244 11.822257-173.901679 35.134195-53.203736 22.509666-100.981902 54.704926-141.987828 95.701642-40.971134 41.006949-73.183789 88.765673-95.693456 141.978618C77.103564 393.211547 65.290517 451.729312 65.290517 512c0 60.278875 11.813047 118.78743 35.126009 173.894516 22.509666 53.211922 54.722322 100.971669 95.693456 141.977595 41.005926 41.006949 88.784092 73.201186 141.987828 95.702666 55.122435 23.311939 113.63099 35.134195 173.901679 35.134195s118.779244-11.822257 173.901679-35.134195c53.203736-22.50148 100.963483-54.695716 141.970432-95.702666 41.005926-41.005926 73.200162-88.765673 95.693456-141.969409 23.330358-55.114249 35.143405-113.63099 35.143405-173.901679 0-20.652366-1.39579-41.10314-4.1536-61.283762L883.151623 450.717262 883.151623 450.716238 883.151623 450.716238zM883.151623 450.716238"
                  p-id="3773"
                  fill="#ffffff"
                ></path>
              </svg>
              <div class="text">
                <!-- <p>{{ AboutUsInfor[4].articlesVoSonList[1].acTit }}</p>
                <p v-html="AboutUsInfor[4].articlesVoSonList[1].acContent"></p> -->
                <p>2019年</p>
                <p>加入大数据产业协会成为会员单位</p>
              </div>
            </div>
            <div class="item">
              <svg
                t="1653440656484"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3772"
                width="32"
                height="32"
              >
                <path
                  d="M498.266719 794.482653 193.422779 479.648175l78.138638-63.594387 176.275749 140.374074c72.242348-87.230714 232.603639-260.313747 453.846532-397.963781l18.619056 43.606147C717.22457 391.518998 550.963918 658.184407 498.266719 794.482653L498.266719 794.482653 498.266719 794.482653 498.266719 794.482653zM883.151623 450.716238c3.28072 19.945261 5.009084 40.413432 5.009084 61.283762 0 207.754694-168.405501 376.161219-376.161219 376.161219-207.737298 0-376.161219-168.405501-376.161219-376.161219 0-207.746508 168.423921-376.152009 376.161219-376.152009 50.15019 0 98.014314 9.841136 141.759631 27.656897L653.759119 88.26323C608.234273 73.011874 560.683281 65.290005 511.999488 65.290005c-60.270688 0-118.779244 11.822257-173.901679 35.134195-53.203736 22.509666-100.981902 54.704926-141.987828 95.701642-40.971134 41.006949-73.183789 88.765673-95.693456 141.978618C77.103564 393.211547 65.290517 451.729312 65.290517 512c0 60.278875 11.813047 118.78743 35.126009 173.894516 22.509666 53.211922 54.722322 100.971669 95.693456 141.977595 41.005926 41.006949 88.784092 73.201186 141.987828 95.702666 55.122435 23.311939 113.63099 35.134195 173.901679 35.134195s118.779244-11.822257 173.901679-35.134195c53.203736-22.50148 100.963483-54.695716 141.970432-95.702666 41.005926-41.005926 73.200162-88.765673 95.693456-141.969409 23.330358-55.114249 35.143405-113.63099 35.143405-173.901679 0-20.652366-1.39579-41.10314-4.1536-61.283762L883.151623 450.717262 883.151623 450.716238 883.151623 450.716238zM883.151623 450.716238"
                  p-id="3773"
                  fill="#ffffff"
                ></path>
              </svg>
              <div class="text">
                <!-- <p>{{ AboutUsInfor[4].articlesVoSonList[2].acTit }}</p>
                <p v-html="AboutUsInfor[4].articlesVoSonList[2].acContent"></p> -->
                <p>2020年8月</p>
                <p>被授予“中国移动优秀合作伙伴”</p>
              </div>
            </div>
            <div class="item">
              <svg
                t="1653440656484"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3772"
                width="32"
                height="32"
              >
                <path
                  d="M498.266719 794.482653 193.422779 479.648175l78.138638-63.594387 176.275749 140.374074c72.242348-87.230714 232.603639-260.313747 453.846532-397.963781l18.619056 43.606147C717.22457 391.518998 550.963918 658.184407 498.266719 794.482653L498.266719 794.482653 498.266719 794.482653 498.266719 794.482653zM883.151623 450.716238c3.28072 19.945261 5.009084 40.413432 5.009084 61.283762 0 207.754694-168.405501 376.161219-376.161219 376.161219-207.737298 0-376.161219-168.405501-376.161219-376.161219 0-207.746508 168.423921-376.152009 376.161219-376.152009 50.15019 0 98.014314 9.841136 141.759631 27.656897L653.759119 88.26323C608.234273 73.011874 560.683281 65.290005 511.999488 65.290005c-60.270688 0-118.779244 11.822257-173.901679 35.134195-53.203736 22.509666-100.981902 54.704926-141.987828 95.701642-40.971134 41.006949-73.183789 88.765673-95.693456 141.978618C77.103564 393.211547 65.290517 451.729312 65.290517 512c0 60.278875 11.813047 118.78743 35.126009 173.894516 22.509666 53.211922 54.722322 100.971669 95.693456 141.977595 41.005926 41.006949 88.784092 73.201186 141.987828 95.702666 55.122435 23.311939 113.63099 35.134195 173.901679 35.134195s118.779244-11.822257 173.901679-35.134195c53.203736-22.50148 100.963483-54.695716 141.970432-95.702666 41.005926-41.005926 73.200162-88.765673 95.693456-141.969409 23.330358-55.114249 35.143405-113.63099 35.143405-173.901679 0-20.652366-1.39579-41.10314-4.1536-61.283762L883.151623 450.717262 883.151623 450.716238 883.151623 450.716238zM883.151623 450.716238"
                  p-id="3773"
                  fill="#ffffff"
                ></path>
              </svg>
              <div class="text">
                <!-- <p>{{ AboutUsInfor[4].articlesVoSonList[3].acTit }}</p>
                <p v-html="AboutUsInfor[4].articlesVoSonList[3].acContent"></p> -->
                <p>2020年10月</p>
                <p>加入山东省软件行业协会成为会员单位</p>
              </div>
            </div>
            <div class="item">
              <svg
                t="1653440656484"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3772"
                width="32"
                height="32"
              >
                <path
                  d="M498.266719 794.482653 193.422779 479.648175l78.138638-63.594387 176.275749 140.374074c72.242348-87.230714 232.603639-260.313747 453.846532-397.963781l18.619056 43.606147C717.22457 391.518998 550.963918 658.184407 498.266719 794.482653L498.266719 794.482653 498.266719 794.482653 498.266719 794.482653zM883.151623 450.716238c3.28072 19.945261 5.009084 40.413432 5.009084 61.283762 0 207.754694-168.405501 376.161219-376.161219 376.161219-207.737298 0-376.161219-168.405501-376.161219-376.161219 0-207.746508 168.423921-376.152009 376.161219-376.152009 50.15019 0 98.014314 9.841136 141.759631 27.656897L653.759119 88.26323C608.234273 73.011874 560.683281 65.290005 511.999488 65.290005c-60.270688 0-118.779244 11.822257-173.901679 35.134195-53.203736 22.509666-100.981902 54.704926-141.987828 95.701642-40.971134 41.006949-73.183789 88.765673-95.693456 141.978618C77.103564 393.211547 65.290517 451.729312 65.290517 512c0 60.278875 11.813047 118.78743 35.126009 173.894516 22.509666 53.211922 54.722322 100.971669 95.693456 141.977595 41.005926 41.006949 88.784092 73.201186 141.987828 95.702666 55.122435 23.311939 113.63099 35.134195 173.901679 35.134195s118.779244-11.822257 173.901679-35.134195c53.203736-22.50148 100.963483-54.695716 141.970432-95.702666 41.005926-41.005926 73.200162-88.765673 95.693456-141.969409 23.330358-55.114249 35.143405-113.63099 35.143405-173.901679 0-20.652366-1.39579-41.10314-4.1536-61.283762L883.151623 450.717262 883.151623 450.716238 883.151623 450.716238zM883.151623 450.716238"
                  p-id="3773"
                  fill="#ffffff"
                ></path>
              </svg>
              <div class="text">
                <!-- <p>{{ AboutUsInfor[4].articlesVoSonList[4].acTit }}</p>
                <p v-html="AboutUsInfor[4].articlesVoSonList[4].acContent"></p> -->
                <p>2021年3月</p>
                <p>被授予“中国电信天翼云金牌服务商”</p>
              </div>
            </div>
          </div>
          <div class="list">
            <div class="item">
              <svg
                t="1653440656484"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3772"
                width="32"
                height="32"
              >
                <path
                  d="M498.266719 794.482653 193.422779 479.648175l78.138638-63.594387 176.275749 140.374074c72.242348-87.230714 232.603639-260.313747 453.846532-397.963781l18.619056 43.606147C717.22457 391.518998 550.963918 658.184407 498.266719 794.482653L498.266719 794.482653 498.266719 794.482653 498.266719 794.482653zM883.151623 450.716238c3.28072 19.945261 5.009084 40.413432 5.009084 61.283762 0 207.754694-168.405501 376.161219-376.161219 376.161219-207.737298 0-376.161219-168.405501-376.161219-376.161219 0-207.746508 168.423921-376.152009 376.161219-376.152009 50.15019 0 98.014314 9.841136 141.759631 27.656897L653.759119 88.26323C608.234273 73.011874 560.683281 65.290005 511.999488 65.290005c-60.270688 0-118.779244 11.822257-173.901679 35.134195-53.203736 22.509666-100.981902 54.704926-141.987828 95.701642-40.971134 41.006949-73.183789 88.765673-95.693456 141.978618C77.103564 393.211547 65.290517 451.729312 65.290517 512c0 60.278875 11.813047 118.78743 35.126009 173.894516 22.509666 53.211922 54.722322 100.971669 95.693456 141.977595 41.005926 41.006949 88.784092 73.201186 141.987828 95.702666 55.122435 23.311939 113.63099 35.134195 173.901679 35.134195s118.779244-11.822257 173.901679-35.134195c53.203736-22.50148 100.963483-54.695716 141.970432-95.702666 41.005926-41.005926 73.200162-88.765673 95.693456-141.969409 23.330358-55.114249 35.143405-113.63099 35.143405-173.901679 0-20.652366-1.39579-41.10314-4.1536-61.283762L883.151623 450.717262 883.151623 450.716238 883.151623 450.716238zM883.151623 450.716238"
                  p-id="3773"
                  fill="#ffffff"
                ></path>
              </svg>
              <div class="text">
                <!-- <p>{{ AboutUsInfor[4].articlesVoSonList[5].acTit }}</p>
                <p v-html="AboutUsInfor[4].articlesVoSonList[5].acContent"></p> -->
                <p>2021年5月</p>
                <p>被评为“双软企业”</p>
              </div>
            </div>
            <div class="item">
              <svg
                t="1653440656484"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3772"
                width="32"
                height="32"
              >
                <path
                  d="M498.266719 794.482653 193.422779 479.648175l78.138638-63.594387 176.275749 140.374074c72.242348-87.230714 232.603639-260.313747 453.846532-397.963781l18.619056 43.606147C717.22457 391.518998 550.963918 658.184407 498.266719 794.482653L498.266719 794.482653 498.266719 794.482653 498.266719 794.482653zM883.151623 450.716238c3.28072 19.945261 5.009084 40.413432 5.009084 61.283762 0 207.754694-168.405501 376.161219-376.161219 376.161219-207.737298 0-376.161219-168.405501-376.161219-376.161219 0-207.746508 168.423921-376.152009 376.161219-376.152009 50.15019 0 98.014314 9.841136 141.759631 27.656897L653.759119 88.26323C608.234273 73.011874 560.683281 65.290005 511.999488 65.290005c-60.270688 0-118.779244 11.822257-173.901679 35.134195-53.203736 22.509666-100.981902 54.704926-141.987828 95.701642-40.971134 41.006949-73.183789 88.765673-95.693456 141.978618C77.103564 393.211547 65.290517 451.729312 65.290517 512c0 60.278875 11.813047 118.78743 35.126009 173.894516 22.509666 53.211922 54.722322 100.971669 95.693456 141.977595 41.005926 41.006949 88.784092 73.201186 141.987828 95.702666 55.122435 23.311939 113.63099 35.134195 173.901679 35.134195s118.779244-11.822257 173.901679-35.134195c53.203736-22.50148 100.963483-54.695716 141.970432-95.702666 41.005926-41.005926 73.200162-88.765673 95.693456-141.969409 23.330358-55.114249 35.143405-113.63099 35.143405-173.901679 0-20.652366-1.39579-41.10314-4.1536-61.283762L883.151623 450.717262 883.151623 450.716238 883.151623 450.716238zM883.151623 450.716238"
                  p-id="3773"
                  fill="#ffffff"
                ></path>
              </svg>
              <div class="text">
                <!-- <p>{{ AboutUsInfor[4].articlesVoSonList[6].acTit }}</p>
                <p v-html="AboutUsInfor[4].articlesVoSonList[6].acContent"></p> -->
                <p>2021年7月</p>
                <p>被评为“国家级科技型中小企业”</p>
              </div>
            </div>
            <div class="item">
              <svg
                t="1653440656484"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3772"
                width="32"
                height="32"
              >
                <path
                  d="M498.266719 794.482653 193.422779 479.648175l78.138638-63.594387 176.275749 140.374074c72.242348-87.230714 232.603639-260.313747 453.846532-397.963781l18.619056 43.606147C717.22457 391.518998 550.963918 658.184407 498.266719 794.482653L498.266719 794.482653 498.266719 794.482653 498.266719 794.482653zM883.151623 450.716238c3.28072 19.945261 5.009084 40.413432 5.009084 61.283762 0 207.754694-168.405501 376.161219-376.161219 376.161219-207.737298 0-376.161219-168.405501-376.161219-376.161219 0-207.746508 168.423921-376.152009 376.161219-376.152009 50.15019 0 98.014314 9.841136 141.759631 27.656897L653.759119 88.26323C608.234273 73.011874 560.683281 65.290005 511.999488 65.290005c-60.270688 0-118.779244 11.822257-173.901679 35.134195-53.203736 22.509666-100.981902 54.704926-141.987828 95.701642-40.971134 41.006949-73.183789 88.765673-95.693456 141.978618C77.103564 393.211547 65.290517 451.729312 65.290517 512c0 60.278875 11.813047 118.78743 35.126009 173.894516 22.509666 53.211922 54.722322 100.971669 95.693456 141.977595 41.005926 41.006949 88.784092 73.201186 141.987828 95.702666 55.122435 23.311939 113.63099 35.134195 173.901679 35.134195s118.779244-11.822257 173.901679-35.134195c53.203736-22.50148 100.963483-54.695716 141.970432-95.702666 41.005926-41.005926 73.200162-88.765673 95.693456-141.969409 23.330358-55.114249 35.143405-113.63099 35.143405-173.901679 0-20.652366-1.39579-41.10314-4.1536-61.283762L883.151623 450.717262 883.151623 450.716238 883.151623 450.716238zM883.151623 450.716238"
                  p-id="3773"
                  fill="#ffffff"
                ></path>
              </svg>
              <div class="text">
                <!-- <p>{{ AboutUsInfor[4].articlesVoSonList[7].acTit }}</p>
                <p v-html="AboutUsInfor[4].articlesVoSonList[7].acContent"></p> -->
                <p>2021年10月</p>
                <p>被评为“东营市科技型企业”</p>
              </div>
            </div>
            <div class="item">
              <svg
                t="1653440656484"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3772"
                width="32"
                height="32"
              >
                <path
                  d="M498.266719 794.482653 193.422779 479.648175l78.138638-63.594387 176.275749 140.374074c72.242348-87.230714 232.603639-260.313747 453.846532-397.963781l18.619056 43.606147C717.22457 391.518998 550.963918 658.184407 498.266719 794.482653L498.266719 794.482653 498.266719 794.482653 498.266719 794.482653zM883.151623 450.716238c3.28072 19.945261 5.009084 40.413432 5.009084 61.283762 0 207.754694-168.405501 376.161219-376.161219 376.161219-207.737298 0-376.161219-168.405501-376.161219-376.161219 0-207.746508 168.423921-376.152009 376.161219-376.152009 50.15019 0 98.014314 9.841136 141.759631 27.656897L653.759119 88.26323C608.234273 73.011874 560.683281 65.290005 511.999488 65.290005c-60.270688 0-118.779244 11.822257-173.901679 35.134195-53.203736 22.509666-100.981902 54.704926-141.987828 95.701642-40.971134 41.006949-73.183789 88.765673-95.693456 141.978618C77.103564 393.211547 65.290517 451.729312 65.290517 512c0 60.278875 11.813047 118.78743 35.126009 173.894516 22.509666 53.211922 54.722322 100.971669 95.693456 141.977595 41.005926 41.006949 88.784092 73.201186 141.987828 95.702666 55.122435 23.311939 113.63099 35.134195 173.901679 35.134195s118.779244-11.822257 173.901679-35.134195c53.203736-22.50148 100.963483-54.695716 141.970432-95.702666 41.005926-41.005926 73.200162-88.765673 95.693456-141.969409 23.330358-55.114249 35.143405-113.63099 35.143405-173.901679 0-20.652366-1.39579-41.10314-4.1536-61.283762L883.151623 450.717262 883.151623 450.716238 883.151623 450.716238zM883.151623 450.716238"
                  p-id="3773"
                  fill="#ffffff"
                ></path>
              </svg>
              <div class="text">
                <!-- <p>{{ AboutUsInfor[4].articlesVoSonList[8].acTit }}</p>
                <p v-html="AboutUsInfor[4].articlesVoSonList[8].acContent"></p> -->
                <p>2021年11月</p>
                <p>荣获“东营市第二届油校融合创新创业大赛企业组优秀奖”</p>
              </div>
              
            </div>
             <div class="item">
              <svg
                t="1653440656484"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3772"
                width="32"
                height="32"
              >
                <path
                  d="M498.266719 794.482653 193.422779 479.648175l78.138638-63.594387 176.275749 140.374074c72.242348-87.230714 232.603639-260.313747 453.846532-397.963781l18.619056 43.606147C717.22457 391.518998 550.963918 658.184407 498.266719 794.482653L498.266719 794.482653 498.266719 794.482653 498.266719 794.482653zM883.151623 450.716238c3.28072 19.945261 5.009084 40.413432 5.009084 61.283762 0 207.754694-168.405501 376.161219-376.161219 376.161219-207.737298 0-376.161219-168.405501-376.161219-376.161219 0-207.746508 168.423921-376.152009 376.161219-376.152009 50.15019 0 98.014314 9.841136 141.759631 27.656897L653.759119 88.26323C608.234273 73.011874 560.683281 65.290005 511.999488 65.290005c-60.270688 0-118.779244 11.822257-173.901679 35.134195-53.203736 22.509666-100.981902 54.704926-141.987828 95.701642-40.971134 41.006949-73.183789 88.765673-95.693456 141.978618C77.103564 393.211547 65.290517 451.729312 65.290517 512c0 60.278875 11.813047 118.78743 35.126009 173.894516 22.509666 53.211922 54.722322 100.971669 95.693456 141.977595 41.005926 41.006949 88.784092 73.201186 141.987828 95.702666 55.122435 23.311939 113.63099 35.134195 173.901679 35.134195s118.779244-11.822257 173.901679-35.134195c53.203736-22.50148 100.963483-54.695716 141.970432-95.702666 41.005926-41.005926 73.200162-88.765673 95.693456-141.969409 23.330358-55.114249 35.143405-113.63099 35.143405-173.901679 0-20.652366-1.39579-41.10314-4.1536-61.283762L883.151623 450.717262 883.151623 450.716238 883.151623 450.716238zM883.151623 450.716238"
                  p-id="3773"
                  fill="#ffffff"
                ></path>
              </svg>
              <div class="text">
                <!-- <p>{{ AboutUsInfor[4].articlesVoSonList[8].acTit }}</p>
                <p v-html="AboutUsInfor[4].articlesVoSonList[8].acContent"></p> -->
                <p>2022年7月</p>
                <p>荣获市级“专精特新”中小企业认定</p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 知识产权及荣誉资质 -->
    <div id="intellectual" style="height:10px"></div>
    <div class="intellectual">
      <!-- <div class="tabs">
        <div
          class="item"
          :class="tabsIndex == index ? 'active' : ''"
          v-for="(item, index) in honorTabs"
          :key="index"
          @click="tabsClick(index)"
        >
          {{ item.acTit }}
        </div>
      </div> -->
      <!-- <div class="tabs_content"> -->
      <!-- <div class="Rotation_box">
          <div class="list">
            <div
              class="item"
              v-for="(ite, idx) in honorTabs[tabsIndex].articlesVoSonList"
              :key="idx"
            >
              <img :src="ite.acImg" alt="" />
              <p>{{ ite.acTit + idx }}</p>
            </div>
          </div>
        </div> -->
      <!-- <Rotation :list="list"></Rotation> -->
      <!-- </div> -->
      <div class="title">
        <span></span>
        <h2>知识产权</h2>
        <!-- <span v-show="lang == '1'">intellectual property</span> -->
      </div>
      <div class="Rotation_box">
        <img
          class="prev"
          src="../assets/icon/left.png"
          alt="上一页"
          @click.stop="prevClick($event)"
        />
        <img
          class="next"
          src="../assets/icon/right.png"
          alt="下一页"
          @click.stop="nextClick($event)"
        />
        <div class="list">
          <div class="item" v-for="(ite, idx) in listZ" :key="idx" v-viewer>
            <img :src="ite.acImg" alt="知识产权图片" />
            <p>{{ ite.acTit }}</p>
          </div>
          <div v-for="(ite, idx) in pageList[page]" :key="idx" class="bigImg">
            <viewer :images="ite.acTit">
              <img v-for="src in ite.acTit" :src="src" :key="src" />
            </viewer>
          </div>
        </div>
      </div>
    </div>
    <div id="honor11" style="height:10px"></div>
    <div class="honor11">
      <div class="title">
        <span></span>
        <h2>荣誉资质</h2>
        <!-- <span v-show="lang == '1'">enterprise honor</span> -->
      </div>
      <div class="Rotation_box">
        <img
          class="prev"
          src="../assets/icon/left.png"
          alt="上一页"
          @click="prevClick1"
        />
        <img
          class="next"
          src="../assets/icon/right.png"
          alt="下一页"
          @click="nextClick1"
        />
        <div class="list">
          <div class="item" v-for="(ite1, idx1) in listR" :key="idx1" v-viewer>
            <img :src="ite1.acImg" alt="荣誉资质图片" />
            <p>{{ ite1.acTit }}</p>
          </div>
          <div
            v-for="(ite1, idx1) in pageList1[page1]"
            :key="idx1"
            class="bigImg"
          >
            <viewer :images="ite1.acTit">
              <img v-for="src in ite1.acTit" :src="src" :key="src" />
            </viewer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Rotation from "../components/Rotation.vue";
export default {
  components: {
    // Rotation,
  },
  metaInfo: {
    title:'关于我们-东营市旭瑞智能科技股份有限公司',  // 设置title
    meta: [{                 
      name: 'keywords',  // 设置关键字
      content: '智慧水利，PLC控制柜，闸泵远程控制，智慧城市，智慧城管，智慧水务，城市防汛监测，智慧闸泵，智慧环卫，智慧公厕，智慧园林，智慧执法，智慧渣土，市容秩序提升，智慧工地，智慧道桥，智慧井盖，智慧管网，智慧照明，智慧农业，智能灌溉，水肥一体化，物联网'
    },
    {
      name: 'description',  // 设置页面描述
      content: '旭瑞智能专注于智慧水利、智慧城管、智慧农业及智能化工程等智慧物联网细分领域软硬件产品研发、生产、销售、运营，提供智慧行业整体解决方案。'
    }],
  },
  data() {
    return {
      list: [
        {
          acImg: require("../assets/Knowledge/城市运行管理系统V1.0.jpg"),
          acTit: "城市运行管理系统",
        },
        {
          acImg: require("../assets/Knowledge/存储设备定位追回系统V1.0.jpg"),
          acTit: "存储设备定位追回系统",
        },
        {
          acImg: require("../assets/Knowledge/防汛监测预警系统V1.0.jpg"),
          acTit: "防汛监测预警系统",
        },
        {
          acImg: require("../assets/Knowledge/精细化路长制巡查及监督管理平台V1.0.jpg"),
          acTit: "精细化路长制管理平台",
        },
        {
          acImg: require("../assets/Knowledge/门禁报警系统V1.0.jpg"),
          acTit: "门禁报警系统",
        },
        {
          acImg: require("../assets/Knowledge/人才招聘小程序V1.0.jpg"),
          acTit: "人才招聘小程序 ",
        },
        {
          acImg: require("../assets/Knowledge/旭瑞供热应急维修处理管理平台V1.0.jpg"),
          acTit: "旭瑞供热应急处理管理平台",
        },
        {
          acImg: require("../assets/Knowledge/旭瑞雨情自动遥测信息化应用软件V1.0.jpg"),
          acTit: "旭瑞雨情自动遥测应用软件",
        },
        {
          acImg: require("../assets/Knowledge/旭瑞智能化灌溉系统管理平台V1.0.jpg"),
          acTit: "旭瑞智能化灌溉系统平台",
        },
        {
          acImg: require("../assets/Knowledge/原油管输在线计量系统V1.0.jpg"),
          acTit: "原油管输在线计量系统",
        },
        {
          acImg: require("../assets/Knowledge/远程人工喊话系统V1.0.jpg"),
          acTit: "远程人工喊话系统",
        },
        {
          acImg: require("../assets/Knowledge/质量管理体系认证证书.jpg"),
          acTit: "质量管理体系认证证书",
        },
        {
          acImg: require("../assets/Knowledge/智慧城管渣土综合监管平台V1.0.jpg"),
          acTit: "智慧城管渣土综合监管平台",
        },
        {
          acImg: require("../assets/Knowledge/智慧公厕综合监管平台V1.0.jpg"),
          acTit: "智慧公厕综合监管平台",
        },
        {
          acImg: require("../assets/Knowledge/智慧环卫综合管理平台V1.0.jpg"),
          acTit: "智慧环卫综合管理平台",
        },
        {
          acImg: require("../assets/Knowledge/智慧路灯管理系统软件V1.0.jpg"),
          acTit: "智慧路灯管理系统软件",
        },
        {
          acImg: require("../assets/Knowledge/智慧水利综合管理平台系统V1.0.jpg"),
          acTit: "智慧水利综合管理平台系统",
        },
        {
          acImg: require("../assets/Knowledge/智慧园林综合管理平台V1.0.jpg"),
          acTit: "智慧园林综合管理平台",
        },
        {
          acImg: require("../assets/Knowledge/智能管网自动化管理系统V1.0.jpg"),
          acTit: "智能管网自动化管理系统",
        },
        {
          acImg: require("../assets/Knowledge/自动语音警告系统V1.0.jpg"),
          acTit: "自动语音警告系统",
        },
        {
          acImg: require("../assets/Knowledge/旭瑞大型工程监理监管综合信息化平台V1.0.jpg"),
          acTit: "旭瑞大型工程监理监管综合信息化平台",
        },
      ],
      listZ: [],
      list1: [
        {
          acImg: require("../assets/honor/大数据产业协会会员单位.jpg"),
          acTit: "大数据产业协会会员单位",
        },
        {
          acImg: require("../assets/honor/电信金牌服务商.jpg"),
          acTit: "电信金牌服务商",
        },
        {
          acImg: require("../assets/honor/移动优秀合作伙伴.jpg"),
          acTit: "移动优秀合作伙伴",
        },
        {
          acImg: require("../assets/honor/山东省软件行业协会会员证书.jpg"),
          acTit: "山东软件行业协会会员证书",
        },
        {
          acImg: require("../assets/honor/软件产品证书-城市运行管理系统V1.0.jpg"),
          acTit: "软件产品证书-城市运行管理系统",
        },
        {
          acImg: require("../assets/honor/软件产品证书-存储设备定位追回系统V1.0.jpg"),
          acTit: "软件产品证书-存储设备定位追回系统",
        },
        {
          acImg: require("../assets/honor/软件产品证书-门禁报警系统V1.0.jpg"),
          acTit: "软件产品证书-门禁报警应用系统 ",
        },
        {
          acImg: require("../assets/honor/软件产品证书-远程人工喊话系统V1.0.jpg"),
          acTit: "软件产品证书-远程人工喊话系统",
        },
        {
          acImg: require("../assets/honor/软件产品证书-智慧水利综合应用管理平台V1.0.jpg"),
          acTit: "软件产品证书-智慧水利综合应用管理平台",
        },
        {
          acImg: require("../assets/honor/软件产品证书-自动语音警告系统V1.0.jpg"),
          acTit: "软件产品证书-自动语音警告系统",
        },
        {
          acImg: require("../assets/honor/软件企业证书.jpg"),
          acTit: "山东省软件企业证书-企业软件证书",
        },
      ],
      listR: [],
      contarray: [],
      honorTabs: [],
      tabsIndex: 1,
      Index: 0,
      lang: "",
      Picurl: "http://101.200.43.114:8710/jeecg-boot/sys/common/static/",
      StaffPiclist: [], // 员工风采相册
      corporatelist: [], // 企业文化列表,
      developmentlist: [], // 发展历程列表
      PatentList: [], // 专利认证列表
      Companyprofile: "",
      img: {},
      color: "#409eff",
      AboutUsInfor: {},
      bgUrl: "",

      totalPage: 0,
      pageList: [],
      page: 0,
      size: 5,

      totalPage1: 0,
      pageList1: [],
      page1: 0,
      size1: 4,
      idx: 0,
      idx1: 0,
    };
  },
  watch: {
    list(newArr, oldArr) {
      console.log(newArr);
      this.page = 0;
      this.fengeList(newArr, this.size);
    },
    list1(newArr1, oldArr1) {
      console.log(newArr1);
      this.page1 = 0;
      this.fengeList1(newArr1, this.size1);
    },
     $route(to,form){
     if(to.name == form.name){
       this.$router.go(0)
     }
    }
  },
  created() {
    this.getArticle();
    this.fengeList(this.list, this.size);
    this.fengeList1(this.list1, this.size1);
    // console.log(this.list.length);
    // this.option();
    //  this.option1();
    //  this.$route.query.name = '';
    if (this.$route.query.id == 9) {
      this.$route.query.name = "";
    }
  },
  mounted() {
    this.lang = localStorage.getItem("newv");
    this.totalPage = Math.ceil(this.list.length / this.size);
    this.totalPage1 = Math.ceil(this.list1.length / this.size1);
    console.log(this.idx);
    this.option();
    this.option1();
  },
  methods: {
    option() {
      // this.$router.push({
      //   query: {
      //     id: 9,
      //   },
      // });
      if (this.idx == 0) {
        this.listZ = [
          {
            acImg: require("../assets/Knowledge/城市运行管理系统V1.0.jpg"),
            acTit: "城市运行管理系统",
          },
          {
            acImg: require("../assets/Knowledge/存储设备定位追回系统V1.0.jpg"),
            acTit: "存储设备定位追回系统",
          },
          {
            acImg: require("../assets/Knowledge/防汛监测预警系统V1.0.jpg"),
            acTit: "防汛监测预警系统",
          },
          {
            acImg: require("../assets/Knowledge/精细化路长制巡查及监督管理平台V1.0.jpg"),
            acTit: "精细化路长制管理平台",
          },
          {
            acImg: require("../assets/Knowledge/门禁报警系统V1.0.jpg"),
            acTit: "门禁报警系统",
          },
        ];
      } else if (this.idx == 1) {
        this.listZ = [
          {
            acImg: require("../assets/Knowledge/人才招聘小程序V1.0.jpg"),
            acTit: "人才招聘小程序 ",
          },
          {
            acImg: require("../assets/Knowledge/旭瑞供热应急维修处理管理平台V1.0.jpg"),
            acTit: "旭瑞供热应急处理管理平台",
          },
          {
            acImg: require("../assets/Knowledge/旭瑞雨情自动遥测信息化应用软件V1.0.jpg"),
            acTit: "旭瑞雨情自动遥测应用软件",
          },
          {
            acImg: require("../assets/Knowledge/旭瑞智能化灌溉系统管理平台V1.0.jpg"),
            acTit: "旭瑞智能化灌溉系统平台",
          },
          {
            acImg: require("../assets/Knowledge/原油管输在线计量系统V1.0.jpg"),
            acTit: "原油管输在线计量系统",
          },
        ];
      } else if (this.idx == 2) {
        this.listZ = [
          {
            acImg: require("../assets/Knowledge/远程人工喊话系统V1.0.jpg"),
            acTit: "远程人工喊话系统",
          },
          {
            acImg: require("../assets/Knowledge/质量管理体系认证证书.jpg"),
            acTit: "质量管理体系认证证书",
          },
          {
            acImg: require("../assets/Knowledge/智慧城管渣土综合监管平台V1.0.jpg"),
            acTit: "智慧城管渣土综合监管平台",
          },
          {
            acImg: require("../assets/Knowledge/智慧公厕综合监管平台V1.0.jpg"),
            acTit: "智慧公厕综合监管平台",
          },
          {
            acImg: require("../assets/Knowledge/智慧环卫综合管理平台V1.0.jpg"),
            acTit: "智慧环卫综合管理平台",
          },
        ];
      } else if (this.idx == 3) {
        this.listZ = [
          {
            acImg: require("../assets/Knowledge/智慧路灯管理系统软件V1.0.jpg"),
            acTit: "智慧路灯管理系统软件",
          },
          {
            acImg: require("../assets/Knowledge/智慧水利综合管理平台系统V1.0.jpg"),
            acTit: "智慧水利综合管理平台系统",
          },
          {
            acImg: require("../assets/Knowledge/智慧园林综合管理平台V1.0.jpg"),
            acTit: "智慧园林综合管理平台",
          },
          {
            acImg: require("../assets/Knowledge/智能管网自动化管理系统V1.0.jpg"),
            acTit: "智能管网自动化管理系统",
          },
          {
            acImg: require("../assets/Knowledge/自动语音警告系统V1.0.jpg"),
            acTit: "自动语音警告系统",
          },
        ];
      } else if (this.idx == 4) {
        this.listZ = [
          {
            acImg: require("../assets/Knowledge/旭瑞大型工程监理监管综合信息化平台V1.0.jpg"),
            acTit: "旭瑞大型工程监理监管综合信息化平台",
          },
        ];
      }
    },
    option1() {
      if (this.idx1 == 0) {
        this.listR = [
          {
            acImg: require("../assets/honor/大数据产业协会会员单位.jpg"),
            acTit: "大数据产业协会会员单位",
          },
          {
            acImg: require("../assets/honor/电信金牌服务商.jpg"),
            acTit: "电信金牌服务商",
          },
          {
            acImg: require("../assets/honor/移动优秀合作伙伴.jpg"),
            acTit: "移动优秀合作伙伴",
          },
          {
            acImg: require("../assets/honor/山东省软件行业协会会员证书.jpg"),
            acTit: "山东软件行业协会会员证书",
          },
        ];
      } else if (this.idx1 == 1) {
        this.listR = [
          {
            acImg: require("../assets/honor/软件产品证书-城市运行管理系统V1.0.jpg"),
            acTit: "软件产品证书-城市运行管理系统",
          },
          {
            acImg: require("../assets/honor/软件产品证书-存储设备定位追回系统V1.0.jpg"),
            acTit: "软件产品证书-存储设备定位追回系统",
          },
          {
            acImg: require("../assets/honor/软件产品证书-门禁报警系统V1.0.jpg"),
            acTit: "软件产品证书-门禁报警应用系统 ",
          },
          {
            acImg: require("../assets/honor/软件产品证书-远程人工喊话系统V1.0.jpg"),
            acTit: "软件产品证书-远程人工喊话系统",
          },
        ];
      } else if (this.idx1 == 2) {
        this.listR = [
          {
            acImg: require("../assets/honor/软件产品证书-智慧水利综合应用管理平台V1.0.jpg"),
            acTit: "软件产品证书-智慧水利综合应用管理平台",
          },
          {
            acImg: require("../assets/honor/软件产品证书-自动语音警告系统V1.0.jpg"),
            acTit: "软件产品证书-自动语音警告系统",
          },
          {
            acImg: require("../assets/honor/软件企业证书.jpg"),
            acTit: "山东省软件企业证书-企业软件证书",
          },
        ];
      }
    },
    fengeList(arr, size) {
      console.log("arr", arr);

      this.pageList = [];
      for (var i = 0; i < arr.length; i += size) {
        this.pageList.push(arr.slice(i, i + size));
        console.log(this.pageList);
      }
    },
    fengeList1(arr1, size1) {
      console.log("arr1", arr1);
      this.pageList1 = [];
      for (var t = 0; t < arr1.length; t += size1) {
        this.pageList1.push(arr1.slice(t, t + size1));
        console.log(this.pageList1);
      }
    },
    prevClick() {
      // console.log("上一页");
      this.idx--;
      if (this.idx <= 0) {
        this.idx = 0;
      }
      this.option();
      this.$router.push({
        query: {
          name: '#intellectual',
        },
      });
    },
    nextClick() {
      this.idx++;
      if (this.idx >= 4) {
        this.idx = 4;
      }
      this.option();
      this.$router.push({
        query: {
          name:'#intellectual',
        },
      });
    },
 
    prevClick1() {
        this.$router.push({
        query: {
          name:'#honor11',
        },
      });
      this.idx1--;
      if (this.idx1 <= 0) {
        this.idx1 = 0;
      }
      this.option1();
    },
    nextClick1() {
        this.$router.push({
        query: {
          name:'#honor11',
        },
      });
      this.idx1++;
      if (this.idx1 >= 2) {
        this.idx1 = 2;
      }
      this.option1();
    },
    getArticle() {
      let data = {
        infoTypeId: 8,
      };
      this.$API.getArticleList(data).then((res) => {
        console.log("res :文章>> ", res);
        this.AboutUsInfor = res.data;
        console.log("企业荣誉 :>>", this.AboutUsInfor);
        this.honorTabs.push(res.data[5]);
        this.honorTabs.push(res.data[6]);

        this.bgUrl = 'url("' + res.data[4].acImg + '")';
        // this.tabsClick(0);
      });
    },
    // tabsClick(index) {
    //   this.tabsIndex = index;
    //   // console.log(index, "角标");
    //   console.log(this.honorTabs);
    //   if (index == 0) {
    //     this.honorTabs[0].articlesVoSonList = [
    //       {
    //         acImg: require("../assets/about/人才招聘小程序.jpg"),
    //         acTit: "人才招聘小程序V1.0-20210318",
    //       },
    //       {
    //         acImg: require("../assets/about/原油管输在线计量系统.jpg"),
    //         acTit: "原油管输在线计量系统V1.0-20211221",
    //       },
    //       {
    //         acImg: require("../assets/about/城市运行管理系统.jpg"),
    //         acTit: "城市运行管理系统V1.0-20211101",
    //       },
    //       {
    //         acImg: require("../assets/about/存储设备定位追回系统.jpg"),
    //         acTit: "存储设备定位追回系统V1.0-20210616",
    //       },
    //       {
    //         acImg: require("../assets/about/旭瑞供热应急维修处理管理平台.jpg"),
    //         acTit: "旭瑞供热应急维修处理管理平台V1.0-20191226",
    //       },
    //       {
    //         acImg: require("../assets/about/旭瑞大型工程监理监管综合信息化平台.jpg"),
    //         acTit: "旭瑞大型工程监理监管综合信息化平台V1.0-20191226",
    //       },
    //       {
    //         acImg: require("../assets/about/旭瑞智能化灌溉系统管理平台.jpg"),
    //         acTit: "旭瑞智能化灌溉系统管理平台V1.0-20200903",
    //       },
    //       {
    //         acImg: require("../assets/about/旭瑞雨情自动遥测信息化应用软件.jpg"),
    //         acTit: "旭瑞雨情自动遥测信息化应用软件V1.0-20191230",
    //       },
    //       {
    //         acImg: require("../assets/about/智慧公厕综合监管平台.jpg"),
    //         acTit: "智慧公厕综合监管平台V1.0-20191225",
    //       },
    //       {
    //         acImg: require("../assets/about/智慧园林综合管理平台.jpg"),
    //         acTit: "智慧园林综合管理平台V1.0-20180806",
    //       },
    //       {
    //         acImg: require("../assets/about/智慧城管渣土综合监管平台.jpg"),
    //         acTit: "智慧城管渣土综合监管平台V1.0-20210318",
    //       },
    //       {
    //         acImg: require("../assets/about/智慧水利综合管理平台系统.jpg"),
    //         acTit: "智慧水利综合管理平台系统V1.0-20190730",
    //       },
    //       {
    //         acImg: require("../assets/about/智慧环卫综合管理平台.jpg"),
    //         acTit: "智慧环卫综合管理平台V1.0-20201027",
    //       },
    //       {
    //         acImg: require("../assets/about/智慧路灯管理系统软件.jpg"),
    //         acTit: "智慧路灯管理系统软件V1.0-20180806",
    //       },
    //       {
    //         acImg: require("../assets/about/智能管网自动化管理系统.jpg"),
    //         acTit: "智能管网自动化管理系统V1.0-20171102",
    //       },
    //       {
    //         acImg: require("../assets/about/精细化路长制巡查及监督管理平台.jpg"),
    //         acTit: "精细化路长制巡查及监督管理平台V1.0-20191224",
    //       },
    //       {
    //         acImg: require("../assets/about/自动语音警告系统.jpg"),
    //         acTit: "自动语音警告系统V1.0-20210616",
    //       },
    //       {
    //         acImg: require("../assets/about/质量管理体系认证证书.jpg"),
    //         acTit: "质量管理体系认证证书",
    //       },
    //       {
    //         acImg: require("../assets/about/远程人工喊话系统.jpg"),
    //         acTit: "远程人工喊话系统V1.0-20210616",
    //       },
    //       {
    //         acImg: require("../assets/about/门禁报警系统.jpg"),
    //         acTit: "门禁报警系统V1.0-20210616",
    //       },
    //       {
    //         acImg: require("../assets/about/防汛监测预警系统.jpg"),
    //         acTit: "防汛监测预警系统V1.0-20201027",
    //       },
    //     ];
    //     this.list = this.honorTabs[0].articlesVoSonList;
    //   }
    //   if (index == 1) {
    //     this.honorTabs[1].articlesVoSonList = [
    //       {
    //         acImg: require("../assets/about/大数据产业协会会员单位.jpg"),
    //         acTit: "大数据产业协会会员单位",
    //       },
    //       {
    //         acImg: require("../assets/about/移动优秀合作伙伴.jpg"),
    //         acTit: "移动优秀合作伙伴",
    //       },
    //       {
    //         acImg: require("../assets/about/电信金牌服务商.jpg"),
    //         acTit: "电信金牌服务商",
    //       },
    //       {
    //         acImg: require("../assets/about/山东省软件行业协会会员证书.jpg"),
    //         acTit: "山东省软件行业协会会员证书",
    //       },
    //       {
    //         acImg: require("../assets/about/软件产品证书-城市运行管理系统V1.0-20211117.jpg"),
    //         acTit: "软件产品证书-城市运行管理系统V1.0-20211117",
    //       },
    //       {
    //         acImg: require("../assets/about/软件产品证书-存储设备定位追回系统V1.0-20210720.jpg"),
    //         acTit: "软件产品证书-存储设备定位追回系统V1.0-20210720",
    //       },
    //       {
    //         acImg: require("../assets/about/软件产品证书-智慧水利综合应用管理平台V1.0-20201027.jpg"),
    //         acTit: "软件产品证书-智慧水利综合应用管理平台V1.0-20201027",
    //       },
    //       {
    //         acImg: require("../assets/about/软件产品证书-自动语音警告系统V1.0-20210720.jpg"),
    //         acTit: "软件产品证书-自动语音警告系统V1.0-20210720",
    //       },
    //       {
    //         acImg: require("../assets/about/软件产品证书-远程人工喊话系统V1.0-20210720.jpg"),
    //         acTit: "软件产品证书-远程人工喊话系统V1.0-20210720",
    //       },
    //       {
    //         acImg: require("../assets/about/软件产品证书-门禁报警系统V1.0-20210720.jpg"),
    //         acTit: "软件产品证书-门禁报警系统V1.0-20210720",
    //       },
    //       {
    //         acImg: require("../assets/about/软件企业证书-20210501.jpg"),
    //         acTit: "软件企业证书-20210501",
    //       },
    //     ];
    //     this.list = this.honorTabs[1].articlesVoSonList;
    //   }
    // },
    // change(index) {
    //   console.log(index);
    //   this.Index = index;
    // },
  },
  updated() {
    if (this.$route.query.name) {
      this.$el.querySelector(this.$route.query.name).scrollIntoView();
    }
  },
};
</script>
<style lang="less" scoped>
h2 {
  font-size: 34px;
}
h3 {
    font-size: 16px;
    color: #004aa1;
}
.content {
  width: 100%;
  // height: 500px;
  .imgbox {
    width: 100%;
    // height: 600px;
    margin-bottom: 50px;
    position: relative;
    // img {
    //   width: 100%;
    //   height: 100%;
    // }
    div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      font-size: 28px;
      color: #fff;
    }
  }

  // 关于我们
  .introduction {
    width: 60%;
    margin: 120px auto 50px;

    .middle {
      display: flex;
      justify-content: space-between;

      img {
        width: 47%;
        // height: 350px;
      }

      .infor_text {
        width: 47%;
        .title {
          padding-left: 20px;
          border-left: 8px solid #004aa1;
          height: 32px;
          line-height: 32px;
          font-size: 34px;
          font-weight: 700;
          display: flex;
        }
      }
      .infor {
        line-height: 26px;
        font-size: 18px;
        text-align: left;
        padding: 15px 0;
        line-height: 30px;
      }
    }

    .bottom {
      line-height: 26px;
      font-size: 18px;
      text-align: left;
      padding: 15px 0;
    }
  }

  // 企业文化
  .enterprise {
    padding-left: 20%;
    margin: 0 auto 50px;
    // background-color: #eee;

    display: flex;

    .left {
      width: 37%;
      padding-top: 50px;

      .title {
        padding-left: 20px;
        border-left: 8px solid #004aa1;
        height: 32px;
        line-height: 32px;
        font-size: 34px;
        font-weight: 700;
        display: flex;
        margin-bottom: 20px;
      }

      .left_top {
        p {
          line-height: 30px;
          padding: 20px 0;
          text-align: left;
        }

        border-bottom: 1px dashed #aaaaaa;
      }

      .left_content {
        display: flex;
        justify-content: space-between;
        text-align: left;
        margin: 20px 0;

        .item {
          width: 40%;

          p {
            line-height: 30px;
          }

          p:nth-child(1) {
            font-weight: 600;
            color: #004aa1;
            line-height: 40px;
          }
        }
      }

      .left_bottom {
        text-align: left;

        p {
          line-height: 30px;
        }

        p:nth-child(1) {
          font-weight: 600;
          color: #004aa1;
          line-height: 40px;
        }
      }
    }

    .right {
      width: 63%;
      height: 600px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  // 核心优势
  .advantages {
    padding: 0 20%;
    margin: 100px auto 50px;

    .title {
      position: relative;
      text-align: center;
      span:nth-child(1) {
        display: block;
        width: 80px;
        height: 8px;
        background-color: #004aa1;
        position: absolute;
        left: 50%;
        transform: translate(-40px, -20px);
      }

      span:nth-child(2),
      span:nth-child(3) {
        //  text-align: center;
        margin: auto;
      }

      span {
        font-size: 34px;
        font-weight: 700;
      }
    }

    .text {
      margin: 20px 0;

      p {
        line-height: 30px;
        font-size: 20px;
      }
    }

    .list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-content: center;

      .item {
        width: 17%;
        height: 280px;
        background-color: #004aa1;
        border-radius: 5px;
        padding: 1%;
        color: rgba(255, 255, 255, 0.85);
        position: relative;

        svg {
          position: absolute;
          top: 30px;
          left: 10px;
        }

        span {
          font-weight: 600;
          font-size: 20px;
          z-index: 9;
          background-color: #004aa1;
          display: block;
          margin: 40px 0;
        }
      }
    }
  }

  // 发展历程
  .history {
    padding: 50px 20%;
    // background-color: #eee;
    margin: 100px auto 0;

    .title {
      position: relative;
      text-align: center;

      span:nth-child(1) {
        display: block;
        width: 80px;
        height: 8px;
        background-color: #004aa1;
        position: absolute;
        left: 50%;
        transform: translate(-40px, -20px);
      }

      span {
        font-size: 34px;
        font-weight: 700;
      }
    }

    .list {
      width: 60%;
      .time_line {
        width: 1152px;
        height: 2px;
        background-color: #004aa1;
        position: relative;
        margin-bottom: 300px;
        .ball {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #004aa1;
          position: absolute;
          top: -6px;
          left: -15px;
        }
        .ball1 {
          width: 10px;
          height: 10px;
          left: 120px;
          top: -5px;
        }
        .ball2 {
          width: 10px;
          height: 10px;
          left: 320px;
          top: -5px;
        }
        .ball3 {
          width: 10px;
          height: 10px;
          left: 600px;
          top: -5px;
        }
        .ball4 {
          width: 10px;
          height: 10px;
          left: 800px;
          top: -5px;
        }
        .ball5 {
          width: 10px;
          height: 10px;
          left: 1000px;
          top: -5px;
        }
        .img1 {
          position: absolute;
          top: -49px;
          left: 119px;
        }
        .img2 {
          top: 6px;
          left: 320px;
        }
        .img3 {
          top: -49px;
          left: 600px;
        }
        .img4 {
          top: 6px;
          left: 800px;
        }
        .img5 {
          top: -49px;
          left: 1000px;
        }
        .img6 {
          position: absolute;
          top: -7px;
          right: -7px;
        }
        .content {
          position: absolute;
          top: -217px;
          left: 0px;
          padding: 0 40px;
          width: 188px;
          height: 160px;
          border: 1px solid #004696;
          border-radius: 5px;
          line-height: 25px;
          text-align: center;
          .p {
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #004aa1;
            line-height: 18px;
            margin-bottom: 5px;
          }
        }
        .content1 {
          width: 347px;
          height: 170px;
          top: 55px;
          left: 108px;
        }
        .content2 {
          width: 422px;
          height: 140px;
          top: -214px;
          left: 340px;
          padding: 0 20px;
          padding-bottom: 20px;
        }
        .content3 {
          width: 440px;
          height: 150px;
          top: 55px;
          left: 570px;
          padding: 0 20px;
          padding-bottom: 20px;
        }
        .content4 {
          width: 200px;
          height: 130px;
          top: -214px;
          left: 870px;
          padding: 0 20px;
          padding-bottom: 20px;
          line-height: 40px;
        }
      }
    }
  }

  // 企业荣誉
  .honor {
    // background-image: url("http://hkimg.xuruidea.com/web_img/cmp_website/qiyerongyu.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: 0 auto 50px;
    color: #fff;

    .content {
      background-color: #004ba1e5;
      padding-top: 50px;
      width: 100%;
      height: 600px;

      .title {
        position: relative;
        text-align: center;

        span:nth-child(1) {
          display: block;
          width: 80px;
          height: 8px;
          background-color: #fff;
          position: absolute;
          left: 50%;
          transform: translate(-40px, -20px);
        }

        span {
          font-size: 34px;
          font-weight: 700;
        }
      }

      .lists {
        display: flex;
        justify-content: space-between;
        width: 60%;
        margin: 0 auto;

        .list {
          width: 43%;

          .item {
            display: flex;
            text-align: left;
            align-items: center;
            height: 90px;
            border-bottom: 1px dashed #fff;

            .text {
              line-height: 30px;
              font-size: 18px;

              p:nth-child(1) {
                font-weight: 600;
              }
            }

            svg {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }

  // .intellectual_honor {
  //   width: 65%;
  //   margin: 0 auto 50px;

  //   .tabs {
  //     width: 50%;
  //     margin: 20px auto;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     border: 1px solid #004aa1;
  //     border-radius: 40px;

  //     .item {
  //       width: 50%;
  //       height: 50px;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       border-radius: 25px;
  //       color: #004aa1;
  //       font-size: 20px;
  //     }

  //     .active {
  //       background-color: #004aa1;
  //       color: #fff;
  //     }
  //   }

  //   .tabs_content {
  //     width: 100%;
  //   }
  // }
  .intellectual {
    margin-top: 100px;
    position: relative;
    .title {
      position: relative;
      text-align: center;
      margin-bottom: 30px;

      span:nth-child(1) {
        display: block;
        width: 80px;
        height: 8px;
        background-color: #004aa1;
        position: absolute;
        left: 50%;
        transform: translate(-40px, -20px);
      }
      span {
        font-size: 34px;
        font-weight: 700;
      }
    }
    .Rotation_box {
      width: 60%;
      height: 400px;
      margin: 0 auto;
      overflow: hidden;

      padding: 0 50px;
      .prev,
      .next {
        position: absolute;
        top: 45%;
      }
      .prev {
        left: 230px;
      }
      .next {
        right: 230px;
      }
      .list {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        .item {
          width: 20%;
          padding: 0 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 230px;
            height: 320px;
            cursor: pointer;
          }
          p {
            width: 200px;
            margin-top: 10px;
          }
        }
        .bigImg {
          display: none;
          width: 0px;
        }
      }
    }
  }
  .honor11 {
    margin-top: 100px;
    position: relative;
    .title {
      position: relative;
      text-align: center;
      margin-bottom: 30px;

      span:nth-child(1) {
        display: block;
        width: 80px;
        height: 8px;
        background-color: #004aa1;
        position: absolute;
        left: 50%;
        transform: translate(-40px, -20px);
        text-align: center;
      }
      span {
        font-size: 34px;
        font-weight: 700;
      }
    }
    .Rotation_box {
      width: 60%;
      height: 400px;
      margin: 0 auto;
      overflow: hidden;

      padding: 0 50px;
      .prev,
      .next {
        position: absolute;
        top: 35%;
      }
      .prev {
        left: 230px;
      }
      .next {
        right: 230px;
      }
      .list {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        .item {
          // width: 20%;
          padding: 0 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          img {
            width: 300px;
            // height: 320px;
            cursor: pointer;
          }
          p {
            width: 200px;
            margin-top: 10px;
          }
        }
        .bigImg {
          display: none;
          width: 0px;
        }
      }
    }
  }
}

/deep/ .el-timeline-item__timestamp.is-top {
  margin-bottom: 8px;
  padding-top: 4px;
  font-size: 25px;
  color: #555555;
  font-weight: bold;
  text-align: left;
}
</style>
